import Airtable from "airtable";
import { Puzzle, PuzzleItem, ErrorPuzzle } from "./../../types";

export const CallAPI = (Base: Airtable.Base) => ({
  // Gets the Puzzle
  getPuzzle: () => {
    console.log("GetPuzzle()");
    // Grab the current week number of the year to grab the correct puzzle
    let now = new Date();
    let onejan = new Date(now.getFullYear(), 0, 1);
    let week = Math.ceil(
      ((now.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7
    );

    // The puzzle for this week
    let ThisWeeksPuzzle: Puzzle;

    // Grab the Puzzle
    return Base("Puzzle")
      ?.select({
        filterByFormula: `AND(Week = "${week}", Year = "${now.getFullYear()}")`,
      })
      .all()
      .then((results) => {
        // Convert to correct type
        const puzzles: Puzzle[] = results.map(
          (r) => r.fields as unknown as Puzzle
        );

        const { ID, Title, Week, Year } = puzzles[0];
        // Set the puzzle
        ThisWeeksPuzzle = {
          ID,
          Title,
          Week,
          Year,
          Items: [],
        };
      })
      .then(() =>
        // Grab the puzzle items
        Base("PuzzleItem")
          ?.select({
            filterByFormula: `PuzzleID = ${ThisWeeksPuzzle?.ID}`,
          })
          .all()
          .then((results) => {
            // Convert to correct type
            const items: PuzzleItem[] = results.map(
              (r) => r.fields as unknown as PuzzleItem
            );
            // Give it to this week's puzzle
            ThisWeeksPuzzle.Items = [...items];
            console.log("GetPuzzle() End:", ThisWeeksPuzzle);
          })
      )
      .then(() => ThisWeeksPuzzle)
      .catch((err) => {
        // Something went wrong, let's handle it well
        console.error(err);
        return ErrorPuzzle;
      });
  },
});
