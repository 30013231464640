export type PuzzleItem = {
  ID: number;
  Content: string;
  Hint: string;
  IsImage: boolean;
  PuzzleID: number;
};

export type Puzzle = {
  ID: number;
  Title: string;
  Items: PuzzleItem[];
  Week: number;
  Year: number;
};

export const DefaultPuzzle: Puzzle = {
  ID: -1,
  Title: "Loading...",
  Items: [],
  Week: -1,
  Year: -1,
};

export const ErrorPuzzle: Puzzle = {
  ...DefaultPuzzle,
  Title: "Error",
};
