import { Home } from "./views";
import { AirtableProvider, PuzzleAPIProvider } from "./utilities";

const App = () => (
  <AirtableProvider>
    <PuzzleAPIProvider>
      <Home />
    </PuzzleAPIProvider>
  </AirtableProvider>
);

export default App;
