import React, { createContext, useContext } from "react";
import { useAirtable } from "./../useAirtable";
import { CallAPI } from "./PuzzleAPI.functions";
import Airtable from "airtable";
import { Puzzle } from "./../../types";

// Defines the functions that come out of the PollAPI
type PuzzleAPI = {
  getPuzzle: () => Promise<Puzzle>;
};

// The Context
export const PuzzleAPIContext = createContext<PuzzleAPI>(
  CallAPI(new Airtable({ apiKey: "Fake" }).base(""))
);

// The hook
export const usePuzzleAPI = () => useContext(PuzzleAPIContext);

// The Provider which provides the stuff
export const PuzzleAPIProvider: React.FC = ({ children }) => {
  // Get Airtable
  const airtable = useAirtable();

  return (
    <PuzzleAPIContext.Provider value={CallAPI(airtable!)}>
      {children}
    </PuzzleAPIContext.Provider>
  );
};
