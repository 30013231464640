import styled from "styled-components";

const StyledPuzzleSquare = styled.div<{ Image?: string }>`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 4vw;
  font-weight: bold;
  padding: 30px;
  box-sizing: border-box;
  word-break: break-all;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-image: url("${(p) => p.Image}");
  background-size: cover;
  background-position: center center;
`;

type PuzzleSquareProps = {
  Content?: string;
  Hint?: string;
  IsImage?: boolean;
};

export const PuzzleSquare = ({
  Content = "",
  Hint = "",
  IsImage = false,
}: PuzzleSquareProps) => {
  console.log("Props:", { Content, Hint, IsImage });
  return (
    <StyledPuzzleSquare Image={IsImage ? Content : undefined} title={Hint}>
      {!IsImage ? Content : undefined}
    </StyledPuzzleSquare>
  );
};
