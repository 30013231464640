import styled from "styled-components";
import { useEffect, useState } from "react";
import { usePuzzleAPI } from "./../utilities";
import { Puzzle, DefaultPuzzle } from "./../types";
import { PuzzleSquare } from "./../components";
import cloneDeep from "lodash.clonedeep";
import sortBy from "lodash.sortby";

const StyledMain = styled.main`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5% 10% 7% 10%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 2vw 1fr;
  grid-template-rows: min-content 50px 1fr 2vw 1fr;
  grid-template-areas: "title title title" "tgap tgap tgap" "zero colgap one" "rowgap rowgap rowgap" "two colgap2 three";

  @media only screen and (max-width: 1000px) {
    padding: 5px;
    grid-template-rows: min-content 10px 1fr 2vw 1fr;
  }
`;

const GridArea = styled.div<{ Area: string }>`
  grid-area: ${(p) => p.Area};
`;

const StyledH1 = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: clamp(1.5rem, 2.5vw, 4rem);
  text-align: center;
  margin: 0px;
`;

export const Home = () => {
  const [puzzle, setPuzzle] = useState<Puzzle>(DefaultPuzzle);

  const { getPuzzle } = usePuzzleAPI();

  useEffect(() => {
    getPuzzle().then((puzzleResult) => setPuzzle(cloneDeep(puzzleResult)));
  }, [getPuzzle]);

  const areas = ["zero", "one", "two", "three"];

  return (
    <StyledMain>
      <GridArea Area="title">
        <StyledH1>{puzzle?.Title}</StyledH1>
      </GridArea>
      {sortBy(puzzle.Items, "ID").map((item, index) => (
        <GridArea Area={areas[index]} key={item.ID}>
          <PuzzleSquare
            IsImage={item.IsImage}
            Content={item.Content}
            Hint={item.Hint}
          />
        </GridArea>
      ))}
    </StyledMain>
  );
};
